<template>
  <div class="w-full h-auto bg-white rounded-sm border border-gray-300 transition-all">
    <p class="flex justify-between items-center py-3 px-5 cursor-pointer" v-clicked="setExpandedState">
      <span class="text-gray-800 font-medium">{{ title }}</span>
      <i :class="`fi fi-rr-angle-small-${toggleContent ? 'up' : 'down'} relative top-1 text-gray-500`"></i>
    </p>
    <div v-show="toggleContent">
      <!-- <hr /> -->
      <div class="py-2 px-5 text-gray-700 text-justify" style="font-size: .92em;">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  data: () => {
    return {
      expanded: false,
    };
  },
  methods: {
    setExpandedState() {
      this.expanded = !this.expanded;
    },
  },
  computed: {
    toggleContent() {
      return this.expanded;
    },
  },
  props: {
    title: String,
  },
};
</script>

<style></style>
