<template>
  <Section
    class="object-cover overflow-hidden relative h-screen"
    id="home"
    v-smartnav
  >
    <div
      class="
        flex
        justify-center
        md:justify-start
        items-center
        w-full
        h-screen
        bg-white
        px-10
        md:px-36
        lg:px-64
      "
    >
      <p
        class="
          text-gray-800
          capitalize
          font-bold
          text-5xl text-center
          md:text-7xl md:text-left
          text-shadow
        "
      >
        Every
        <span class="block"
          >Bit <span class="text-green-700 font-black">Counts.</span></span
        >
        <span class="text-xl font-normal normal-case ml-1"
          >Scroll down to read about what we are doing.</span
        >
      </p>
    </div>
  </Section>

  <!-- Programs -->
  <!-- <Section class="py-10 px-10 md:px-36">
    <h3 class="text-4xl font-semibold text-gray-800">Programs</h3>
    <p class="text-gray-600 mt-2 text-sm">
      In order to accomplish its objectives. Rise Malawi has implemented the
      following projects. Click on a project to read more about it.
    </p>

    <br />
    <CEGIWENET />
    <ECD class="mt-4" />
    <YouthEmpowerment class="mt-4" />
    <STOPSVAG class="mt-4" />
    <HIV class="mt-4" />
    <ChildMarriage class="mt-4" />
  </Section> -->

  <Section class="px-5 md:px-36 lg:px-64 py-10 bg-white">
    <div class="flex flex-col md:flex-row justify-between">
      <div class="flex justify-start items-baseline">
        <h4 class="text-4xl font-bold text-gray-800">Our Thematic Areas</h4>
        <div class="h-1 w-32 ml-2 bg-green-700"></div>
      </div>

      <div class="w-full md:w-1/2 mt-5 md:mt-0">
        <Accordion title="Human Rights">
          <div>
            <p>
              RISE integrates Human rights principles and gender transformative
              approaches in its project design and implementation under
              programmes. To ensure equality and non-discrimination during
              implementation, stakeholders and beneficiaries especially women
              and girls are given equal opportunity to contribute to decision
              making.
            </p>
          </div>
        </Accordion>

        <br />
        <Accordion title="SRHR">
          <div>
            <p>
              Rise is creating safe places for girls, young women and young men
              to access SRHR and Family Planning information, services and
              resources and discuss SRHR, Family Planning and abortion issues
              with their peers or health facility staff.
            </p>
          </div>
        </Accordion>

        <br />
        <Accordion title="Education">
          <div>
            <p>
              At RISE we strongly believe that education is the greatest tool to
              breaking the vicious cycle of poverty, desperation and ignorance
            </p>
          </div>
        </Accordion>

        <br />
        <Accordion title="Health">
          <div>
            <p>
              Our Programs on Health are: HIV Prevention, Treatment and Care,
              Adolescent Youth Sexual Reproductive Health and Rights, Empowering Young People
              Living With HIV, Advocacy and Lobbying on Health Issues, Capacity
              Building &amp; Research.
            </p>
          </div>
        </Accordion>
      </div>
    </div>

    <br /><br /><br />
    <hr />
    <br /><br />
    <div>
      <h4 class="text-4xl font-bold text-gray-800">Our Programmes</h4>
    </div>

    <!-- <br><br>
    <div class="w-2/3">
      <Accordion title="Project Name">
        <div>
          <h4 class="font-medium mb-3">Duration:</h4>
          <h4 class="font-medium mb-3">Donor:</h4>
          <h4 class="font-medium mb-3">Geographical Area:</h4>
          <h4 class="font-medium mb-3">Target Group:</h4>

          <br>
          <h4 class="font-bold mb-1">Project Description</h4>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rerum
            ducimus, est aperiam consequuntur, non quasi quibusdam eos quo
            exercitationem minima magnam similique voluptates hic nulla fugiat
            temporibus recusandae, iste asperiores.
          </p>

          <br>
          <h4 class="font-bold mb-1">Project Objectives</h4>


          <br>
          <h4 class="font-bold mb-1">Achievements</h4>
        </div>
      </Accordion>
    </div> -->

    <br /><br />
    <div class="w-full md:w-2/3">
      <Accordion title="LET GIRLS LEARN-ATSIKANA APHUNZIRE">
        <div>
          <!-- <h4 class="font-medium mb-3">Duration:</h4>
          <h4 class="font-medium mb-3">Donor:</h4>
          <h4 class="font-medium mb-3">Geographical Area:</h4>
          <h4 class="font-medium mb-3">Target Group:</h4> -->

          <br />
          <h4 class="font-bold mb-1">Programme Description</h4>
          <p>
            The programme works with girls who have never been in school or
            dropped out of Primary school aged 10-15 years. These girls are
            Illiterate, burdened by domestic work by their parents and guardians
            compared to boys. Some of these girls are orphaned girls who are
            heads of their households and have never accessed an education
            before.
          </p>

          <br />
          <p>
            These girls were further marginalized because of conservative gender
            norms that encourage girls to dropout after primary to marry, not
            having access to a secondary school nearby and high rates of poverty
            that force girls to work. Research shows that currently only 22% of
            girls complete primary school, very few going on to secondary
            education (Dept of Education, 2014).
          </p>

          <br />
          <p>
            According to Save the Children's report "State of The World's
            Mothers". Malawi's completion rate for girls to grade five remains
            significantly low. Gender and educational norms, poverty and poor
            sexual and reproductive health are significant barriers to girls
            retention, achievement and learning in primary schools in Malawi.
            Traditional gender norms mean there is increased pressure on girls
            to remain at home, rather than attend school. Where there are
            limited resources, parents prefer to invest in the education of
            their sons.
          </p>

          <br />
          <p>
            Within our recent baseline study, 25% of families said that gender
            played a role in their decision on whether to send a child to school
            and 32% said it made more sense to send a boy as they are more
            likely to use their education. Poor quality teaching in primary
            schools concerning sexual and reproductive health and rights (SRHR)
            due to teachers feeling uncomfortable to address issues results in
            poor SRHR for girls.
          </p>

          <br />
          <p>
            Additionally lack of a safe learning environment for female students
            who have been open to incidents of sexual abuse from male peers and
            teachers increases girls likelihood of not attending school (Nsanje
            Baseline Report, 2012). Finally there is lack of attention to the
            SRH needs of girls in general, for example the provision of
            inadequate sanitation facilities during menstruation and a lack of
            support for pregnant or new mothers, is also a contributing factor
            to girls low attendance, retention and achievement at school.
          </p>

          <!-- <br />
          <h4 class="font-bold mb-1">Project Objectives</h4> -->

          <br />
          <h4 class="font-bold mb-1">Achievements</h4>
          <p>
            Over the past 4 years the programme has changed lives of over 12,000
            marginilsed girls, as it is improving girls literacy, numeracy and
            other life skills .The programme empowers adolecent girls and tackles
            harmful social and gender norms that contribute to girls being out
            of school. Through Life skills empowerment girls have achieved and
            improved quality of their family lives as they have become active
            participants in decision making relating to their family or
            community. The programme also addresses the gender, poverty, social,
            health and cultural barriers faced by them. This has been achieved
            by engaging girls to build their self-esteem and confidence to
            enroll in school.
          </p>
        </div>
      </Accordion>
    </div>

    <br /><br />
    <div class="w-full md:w-2/3">
      <Accordion title="THE YOUTH LEADERSHIP DEVELOPMENT INITIATIVE">
        <div>
          <!-- <h4 class="font-medium mb-3">Duration:</h4>
          <h4 class="font-medium mb-3">Donor:</h4>
          <h4 class="font-medium mb-3">Geographical Area:</h4>
          <h4 class="font-medium mb-3">Target Group:</h4> -->

          <br />
          <h4 class="font-bold mb-1">Project Description</h4>
          <p>
            At RISE we strongly believe that education is the greatest tool to
            breaking the vicious cycle of poverty, desperation and ignorance. As
            Nelson Madiba Mandela said, and we quote: "Education is the great
            engine of personal development. It is through education that the
            daughter of a peasant can become a doctor, that the son of a
            mineworker can become the head of the mine that a child of farm
            workers can become the president of a great nation. It is what we
            make out of what we have, not what we are given, that separates one
            person from another." NELSON MANDELA- long walk to freedom.
          </p>

          <br />
          <p>
            RISE is implementing an innovative and comprehensive education
            program that is empowering youths through education in the following
            approaches: Educational support at secondary and tertiary levels,
            creation of Youth Leadership Development Initiative that has over 3
            years provided 200 youths with free computer and ICT training,
            books, internet access and online educational resources; and
            creating social forums and ICT Clubs where 1, 200 youths per year
            are benefiting. Young people are also engaged in SRHR education and
            empowerment and increasing access to SRHR resources, services and
            information to them.
          </p>

          <!-- <br />
          <h4 class="font-bold mb-1">Project Objectives</h4>

          <br />
          <h4 class="font-bold mb-1">Achievements</h4> -->
          <p></p>
        </div>
      </Accordion>
    </div>

    <br /><br />
    <div class="w-full md:w-2/3">
      <Accordion title="THE RISE EARLY CHILDHOOD DEVELOPMENT">
        <div>
          <!-- <h4 class="font-medium mb-3">Duration:</h4>
          <h4 class="font-medium mb-3">Donor:</h4>
          <h4 class="font-medium mb-3">Geographical Area:</h4>
          <h4 class="font-medium mb-3">Target Group:</h4> -->

          <br />
          <h4 class="font-bold mb-1">Project Description</h4>
          <p>
            Our Approach to ECD Provision, Expansion and Advocacy RISE
            implements an Early Childhood Development programme that is a
            comprehensive and human rights based approach to policies and
            INTERVENTIONS for marginalized rural children from conception to age
            8 aimed at promoting their survival, growth, development, education,
            protection and active participation to ensure they are effectively
            prepared for primary school.
          </p>

          <br />
          <p>
            Based on the National ECD Strategy and the Convention on the Rights
            of Children-CRC, this programme provides and advocate for good
            quality early childhood education for marginalized children to
            ensure they have a good start when they transition to primary school
            by focusing o n access and equity, quality and relevance,
            infrastructure and capacity building and community mobilization in
            improving access to integrated early childhood care and education
            services for them.
          </p>

          <br />
          <p>
            The following core INTERVENTIONS are being implemented: Advocacy for
            the recognition of early childhood care and education services as a
            national priority and for increased investment in ECD, child
            protection INTERVENTIONS, social economic empowerment for families
            to provide for the education and health needs for children, capacity
            building in effective parenting skills for parents and caregivers
            through education in health, nutrition, injury prevention and
            improved early stimulation at home, community and ECD center.
          </p>

          <br />
          <h4 class="font-bold mb-1">Project Objectives</h4>
          <ul class="px-8">
            <li
              v-for="(objective, i) in objectives.ecd"
              :key="i"
              class="text-sm list-disc"
            >
              {{ objective }}
            </li>
          </ul>

          <br />
          <h4 class="font-bold mb-1">Achievements</h4>
          <p>
            Increased number of children enrolled and retained in CBCC Centers
            and successfully transitioning to primary school.
          </p>

          <br />
          <p>
            Enhanced early learning and stimulation among the children 3 to 8
            years.
          </p>

          <br />
          <p>Improved holistic child development.</p>

          <br />
          <p>
            Reduced cases of malnutrition and hygienic related diseases like
            tape worms, cholera and diahorea among the children.
          </p>
        </div>
      </Accordion>
    </div>

    <br /><br />
    <div class="w-full md:w-2/3">
      <Accordion title="SRHR PROGRAMME">
        <div>
          <!-- <h4 class="font-medium mb-3">Duration:</h4>
          <h4 class="font-medium mb-3">Donor:</h4>
          <h4 class="font-medium mb-3">Geographical Area:</h4>
          <h4 class="font-medium mb-3">Target Group:</h4> -->

          <!-- <br />
          <h4 class="font-bold mb-1">Project Description</h4> -->
          <p>
            In Malawi, a survey by the Ministry of Health (MOH) shows that over
            70,000 women do abortion in a year, the majority of whom are young
            unmarried women and girls of between 15 and 35 years of age.
            Averages of 24 abortions occur for every 1, 000 women of this age
            bracket. Around 31,000 maternal deaths per annum are attributed to
            unsafe abortions.According to the 2010 Demographic and Health
            Survey, the average maternal mortality ratio during the seven-year
            period before the survey was 675 deaths per 100,000 live births
            (range, 570–780).
          </p>

          <br />
          <p>
            Studies at large health facilities in Lilongwe and Dowa districts
            indicated that 24% of maternal deaths in 2010 were due to post
            abortion complications. Despite restrictive laws on abortion in
            Malawi, a study by the Family Planning Association of Malawi
            revealed that vulnerable women and girls resort to seeking unsafe
            abortion for a variety of reasons, including poverty, unplanned
            pregnancy, coercion, shame, family disapprovals, and fear of being
            forced out of schools.
          </p>

          <br />
          <p>
            In a recent UNICEF study, 20% of 12-14 year old girls, and over 30%
            of 15-24 years old girls and young women, reported having a close
            friend who had attempted to abort. The impact of unsafe abortion on
            girls and young women is enormous. Unsafe abortion it the second
            leading cause of pregnancy-related mortality in Malawi, accounting
            for approximately 18% of all maternal deaths while the rate of
            unsafe abortions is at 35%. Rural areas, which are home to 85% of
            Malawi’s population, have virtually no abortion services.
          </p>

          <br />
          <p>
            Awareness and understanding of available SRHR and Family Planning
            services, emergency contraceptives and benefits of safe abortions
            among girls, young women and men aged 15-24 yearsis very low.
            Negative societal norms and attitudes towards family planning and
            contraceptives and abortion persist(Munthali et. al, 2004). In urban
            and semi urban areas in Lilongwe district and in rural villages in
            Dowa district,young unmarried women and girls are denied access to
            safe abortion as they face a range of specific barriers resulting
            from restrictive legislation among others.
          </p>

          <br />
          <p>
            In Malawian laws, any person administering abortion can be guilty
            under section 149 of the penal code and can be sentenced to up to 14
            years imprisonment. Women who solicit an abortion can be sentenced
            to 7 years under section 156. With the legal restrictions in place
            on abortion in Malawi and in the communities where RISE works, the
            practice still occurs and is usually performed by untrained
            personnel or induced by women and girls themselves.
          </p>

          <br />
          <p>
            Further barriers also contribute to the problem. Lack of trained
            service providers, lack of health facilities within these rural
            areas, lack of knowledge and information on benefits and
            accessibility of safe abortion services as a human right within the
            community; and cultural barriers like objections from religious
            institutions and political groups and community leaders. As a
            result, not only do many women and girls die from unsafe abortions,
            but many suffer long-term psychological and health consequences,
            including chronic pain and sterility.
          </p>

          <br />
          <h4 class="font-bold mb-1">Projects Description</h4>

          <br />
          <h4 class="font-bold mb-1">
            Project 1: Women’s Integrated Sexual Health–WISH
          </h4>

          <p>
            WISH is an integrated right based approach with a purpose of
            improving and advancing women’s rights to safe abortion and their
            sexual and reproductive health and rights (SRHR). This initiative is
            addressing specific urgent problems. It is focusing on legal reform
            for creating support for women’s rights access to SRHR services as a
            human rights and providing correct information of SRHR, on benefits
            of safe abortion, and access to contraception.
          </p>

          <br />
          <p>
            The project is contributing to addressing key barriers to SRHR and
            safe abortion services such as lack of knowledge and information
            among girls and young women and the community on issues of abortion
            and SRHR, and unsupportive objections from religious, political
            groups and community leaders.
          </p>

          <br />
          <p>
            Other key barriers that the project is addressing include stigma or
            negative attitudes of community on young women access to abortion
            and lack of quality post abortion care from service providers.
          </p>

          <br />
          <p>
            Essentially, the project is a Sexual and Reproductive Health Rights
            approach that integrates activities advocating for access to safe
            abortion services with those advocating for contraception and family
            planning in order to help reduce maternal mortality caused by unsafe
            abortions in Malawi.
          </p>

          <br />
          <h4 class="font-bold mb-1">Project Objectives</h4>
          <ul class="px-8">
            <li
              v-for="(objective, i) in objectives.wish.wish"
              :key="i"
              class="text-sm list-disc"
            >
              {{ objective }}
            </li>
          </ul>

          <br />
          <h4 class="font-bold mb-1">
            Project 2: Women’s Hope in Overseeing and Leveraging Equal Access To
            Safe Abortion (WHOLE Access To Safe Abortion).
          </h4>

          <p>
            Women’s Hope in Overseeing and Leveraging Equal Access To Safe
            Abortion (WHOLE Access To Safe Abortion) advocates for women’s
            rights to safe abortion and Sexual Reproductive Health and Rights
            (SRHR) services. The project contributed to current abortion
            legalization debate, drafting new and progressive abortion laws and
            advocacy for draft bills to be tabled in parliament and passed into
            law to legalize abortion while increasing access to SRHR and safe
            abortion services.
          </p>

          <br />
          <p>
            The project main components are advocacy, capacity building,
            community education and creating effective partnerships for SRHR and
            safe abortion services. Expected outcomes include improved laws and
            policies governing abortion, increased awareness of and support for
            safe abortion, increased access to SRHR and safe abortion services
            and reduced maternal deaths resulting from unsafe abortions.
          </p>

          <br />
          <p>
            The project main components are advocacy, capacity building,
            community education and creating effective partnerships for SRHR and
            safe abortion services. Expected outcomes include improved laws and
            policies governing abortion, increased awareness of and support for
            safe abortion, increased access to SRHR and safe abortion services
            and reduced maternal deaths resulting from unsafe abortions.
          </p>

          <br />
          <p>
            Capacity building is a core strategy that will help ensure
            sustainability. Trained service providers and community advocates
            will provide SRHR education, contraceptives and safe abortion
            services including post-abortion care to women and girls after this
            funding. RISE is address gender inequality issues by involving women
            in designing activities addressing gender in-equality. Men, boys and
            women are being empowered to protect women’s meaningful
            participation and recognition.
          </p>

          <br />
          <p>
            To advance women's equal participation and supporting women and
            girls in realizing their rights, this innovation is using Girls SRHR
            Cycles, CAHEFs and Girls Read and Learn structures to address toxic
            male attitudes that perpetuate discriminating against women. Using
            Whole Community Approach, villages are being engaged in women’s
            rights empowerment to address gender inequalities.
          </p>

          <br />
          <h4 class="font-bold mb-1">Project Objectives</h4>
          <ul class="px-8">
            <li
              v-for="(objective, i) in objectives.wish.whole"
              :key="i"
              class="text-sm list-disc"
            >
              {{ objective }}
            </li>
          </ul>

          <br />
          <h4 class="font-bold mb-1">Achievements</h4>
          <p>
            The trained social workers, mid-level persons and counselors have
            attained improved capacities on providing appropriate information on
            safe methods of abortion, counseling, access and its availability.
          </p>

          <br />
          <p>
            The community focused interventions have been directly built the
            capacities of women, girls and young men to address stigma,
            discrimination, power imbalances and the marginalization of women
            and girls which contribute to poor reproductive health outcomes and
            limit women’s and girls’ access to quality SRHR and safe abortion
            services and emergency contraceptive and family planning services.
          </p>

          <br />
          <p>
            The project has expanded access to contraceptives information and
            services for rural and marginalized women, girls and young people.
            This has contributed towards reduction of unplanned pregnancies due
            to increased access to contraceptive among girls and young women.
          </p>

          <br />
          <p>
            The project has strengthened referral linkages between health
            facilities and respective communities which have helped women and
            girls to access quality and comprehensive SRHR services.
          </p>

          <br />
          <p>
            The innovation has strengthened individual and community abilities
            to prevent unwanted pregnancies and decrease unsafe abortions by
            increasing access to family planning and contraception services and
            comprehensive abortion information, care and services as a basic
            human right for young women, girls and young men.
          </p>
        </div>
      </Accordion>
    </div>

    <!-- <br /><br />
    <div class="w-full md:w-2/3">
      <Accordion title="Rise Gender Based Violence Program">
        <div>
          <h4 class="font-medium mb-3">Duration:</h4>
          <h4 class="font-medium mb-3">Donor:</h4>
          <h4 class="font-medium mb-3">Geographical Area:</h4>
          <h4 class="font-medium mb-3">Target Group:</h4>

          <br />
          <h4 class="font-bold mb-1">Project Description</h4>
          <p>
            Violence against girls is on the rise in Lilongwe and Dowa
            districts. 50% of girls in school knew at least one girl approached
            by a teacher for sex according to recent evaluations by UNICEF. 38.7
            percent of girls knew of teachers having sex with girl students. 64%
            of girls said they had problems with boys in school, including that
            boys had touched their breasts or buttocks. 80% of girls reported
            experiencing verbal or sexual abuse on the way to or from school and
            being beaten by teachers. 95% of girls indicated that they did not
            report violence incidents to authorities, noting reasons such as
            that those schools would not do anything and fear of retaliation
            (The Policy Project, 2015).
          </p>

          <br />
          <p>
            Forty-one percent of girls experience either physical or sexual
            violence in Malawi. 16% experience physical violence; 13% experience
            sexual violence; and 12% experience both physical and sexual
            violence. 65% of girls experience sexual abuse during their
            lifetime. 50% of girls are married before their 18th birthday
            (UNICEF, 2013). RISE will target Lilongwe and Dowa districts and
            implement rights based, holistic and multi-sectoral responses to
            prevent Sexual Violence against Girls (VAG) and to promote and
            address girl's inter-related rights and needs.
          </p>

          <br />
          <p>
            Main strategies include advocacy, capacity building, strengthening
            legal protections, creating referrals for psychosocial therapy and
            medical services, legal support for VAG survivors and improved
            engagement with men, boys and community leaders in challenging male
            stereotypes to prevent and eliminate VAG by transforming harmful
            social norms.
          </p>

          <br />
          <h4 class="font-bold mb-1">Project Objectives</h4>
          <ul class="px-8">
            <li
              v-for="(objective, i) in objectives.gbv"
              :key="i"
              class="text-sm list-disc"
            >
              {{ objective }}
            </li>
          </ul>

          <br />
          <h4 class="font-bold mb-1">Achievements</h4>
          <p>
            The project has built the capacities for villages to eradicate
            harmful cultural practices that perpetuate SGBV, provide
            psychosocial therapy and use legal instruments to prevent and report
            SGBV. It has also enhanced the capacities for girls to claim their
            SRH rights, demand SRHR services, report SGBV cases and address
            gender inequalities through rights education, SRHR advocacy and GBV
            awareness creation.
          </p>

          <br />
          <p>
            The project has tackled gender imbalances, rights knowledge gaps and
            empowers communities to use rights protection protocols in
            preventing and reporting SGBV.
          </p>

          <br />
          <p>
            The project has contributed towards changing community attitudes and
            behaviours to protect girls’ rights using available policies and
            laws.
          </p>

          <br />
          <p>
            The project has strengthened individual, community and school
            capacities to effectively report and prevent SGBV.
          </p>

          <br />
          <p>
            The project has built service seeking behaviour among the SGBV
            victims, which is increasing their access to psychosocial therapy,
            medical care and legal support services.
          </p>

          <br />
          <p>
            The project is addressing harmful cultural practices that perpetuate
            SGBV by engaging men, boys, adolescent girls, teachers, the police
            and communities in rights education. This will help for community
            members to recognize and respect the rights of girls.
          </p>

          <br />
          <p>
            The project has engaged men, boys and community leaders in rights
            education, community mobilization and integrated rights based
            approaches in addressing root causes of SGBV.
          </p>

          <br />
          <p>
            The project has reduced HIV vulnerabilities among girls by
            increasing their access to SRHR resources and services
          </p>
        </div>
      </Accordion>
    </div> -->
  </Section>
</template>

<script>
import Section from "@/components/Section";
import Accordion from "@/components/Accordion";
// import ECD from "@/programs/ECD.vue";
// import CEGIWENET from "@/programs/CEGIWENET.vue";
// import YouthEmpowerment from "@/programs/YouthEmpowerment.vue";
// import STOPSVAG from "@/programs/STOP-SVAG.vue";
// import HIV from "@/programs/HIV.vue";
// import ChildMarriage from "@/programs/ChildMarriage.vue";

export default {
  name: "Programs",
  data() {
    return {
      objectives: {
        ecd: [
          "Improve ECD services provision to children at the CBCC, Household and Community Levels",
          "Increase number of children enrolling at the CBCC level, accessing quality ECD services and retained in school",
          "Increase number of children starting/graduating to standard 1 class directly from the CBCC set-up",
          "Improve quality of ECD services, care and support for children with special needs at the community and CBCC levels",
          "Improve infrastructure and learning environment for children at the CBCC level",
          "Improve capacities and skills for caregivers working with children at the CBCCs",
          "Improve community engagement, involvement and ownership of ECD INTERVENTIONS",
          "Improve collaboration among CSOs, government departments and ministries, CBOs, FBOs and other stakeholders in implementing and promoting ECD services in Malawi",
          "Increase awareness on the importance and benefits of ECD at the community, local and national levels",
          "Increase awareness of ECD policies and its importance as a national priority among key stakeholders and duty bearers in Malawi",
          "Increase resource allocation and prioritization of ECD in Malawi at the community level and local and national government level",
        ],
        wish: {
          wish: [
            "To increase support for the passing of the Termination of Pregnancy Bill into Law by creating a network of champions among religious leaders, chiefs, legislators and policy makers in Malawi by September, 2020.",
            "To increase awareness and understanding of SRHR and Family Planning services and Emergency Contraceptives among girls, young women and young men aged 15-24 years to increase access to available SRHR services and emergency contraceptives for them in Lilongwe and Dowa districts by September, 2020.",
            "To increase knowledge and capacities among service providers for increased access to quality and comprehensive abortion care, including contraception for girls and young women aged 15-24 years in Lilongwe and Dowa districts by September, 2020.",
          ],
          whole: [
            "To reduce unsafe abortion incidences by 30% from 35% to 5% for 3,000 marginalized unmarried young women and girls by increasing their access to quality and comprehensive abortion services and contraception in Lilongwe by December, 2020.",
            "To improve knowledge and capacities by 60% from 10% to 7% for 10 service providers for increased access to quality and comprehensive abortion care, including contraception for 2,000 girls and young women aged 15-24 years in Lilongwe and Dowa districts by December, 2020.",
            "To increase support for legal and policy reforms on safe abortion by among 1,000 religious leaders, 1,200 chiefs and 200 legislators and 100 policy makers who will support legal and policy reforms on safe abortion in Malawi by December, 2020.",
            "To increase access to available contraceptives and modern family planning services by 50% from 6% to 56% for 6,000 unmarried young women and girls by tackling stigma and discrimination and negative societal norms and attitudes towards abortion in Dowa by September, 2020.",
          ],
        },
        gbv: [
          "To reduce GBV incidences in 300 villages and 100 schools by 46% from 51% to 5% among 5,000 girls using GBV laws and policies to protect girls’ rights.",
          "To increase numbers of reported GBV cases among 5,000 girls by 80% from 5% to 85% by improving GBV monitoring capacities of 400 men, 200 boys and 100 teachers.",
          "To increase access to psychosocial therapy and SRHR, medical, and legal remedy services by 91% from 9% to 100% for 1, 000 girl survivors of GBV.",
        ],
      },
    };
  },
  components: {
    Section,
    Accordion,
    // ECD,
    // CEGIWENET,
    // YouthEmpowerment,
    // STOPSVAG,
    // HIV,
    // ChildMarriage,
  },
};
</script>

<style scoped>
.landing-parallax {
  /* The image used */
  background-image: url("../assets/images/parallax2.jpg");

  /* Set a specific height */
  height: 100vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
